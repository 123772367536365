import styled from 'styled-components'

const StyledGradient = styled.span`
    box-decoration-break: clone;    
    -webkit-box-decoration-break: clone;
`
const Gradient = (props) => {
    const colors = {
        default: 'from-purple-500 to-indigo-500',
        purple: 'from-indigo-500 to-purple-500',
    };
    return (
        <StyledGradient
            className={`bg-gradient-to-r ${
                colors[props.theme]
            } bg-clip-text text-transparent transition duration-500 ease-in-out hover:bg-gradient-to-r hover:from-indigo-500 hover:to-purple-500`}
        >
            {props.children}
        </StyledGradient>
    );
};

export default Gradient;
