import React, { useState, useContext } from 'react';
import { useRouter } from 'next/router';

import { uo_url } from '../helpers/url';

import {
    ChartPieIcon,
    CalculatorIcon,
    PhoneOutgoingIcon,
    CodeIcon,
    ChartBarIcon,
    PencilAltIcon,
    UsersIcon,
    AcademicCapIcon,
    ArrowDownIcon,
    ChevronDownIcon,
} from '@heroicons/react/solid';

import { SearchContext } from '../context/SearchContext';

import { Button } from '../components/Atoms/Elements';
import Gradient from '../components/Atoms/Text/Gradient';

import { JobCard, BranchCard } from '../components/Molecules/Card';
import { BasicSearchForm } from '../components/Molecules/Form';

import Layout from '../components/Layout';

const Landing = ({ jobs }) => {
    const router = useRouter();
    const { branchList, jobList } = useContext(SearchContext);

    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobOptions, setJobOptions] = useState([]);

    const changeField = (value, type) => {
        if (type === 'branch') {
            const filterJobTitles = jobList.filter((job) => job.branch === value._id);
            setJobOptions(filterJobTitles);
            return setSelectedBranch(value);
        }
        if (type === 'job') {
            return setSelectedJob(value);
        }
    };

    const navigateToSearch = (e) => {
        e.preventDefault();

        if (selectedBranch && selectedJob) {
            return router.push(`/s?branch=${selectedBranch.enum}&job=${selectedJob.enum}`);
        }

        if (selectedBranch && !selectedJob) {
            return router.push(`/s?branch=${selectedBranch.enum}`);
        }
    };

    return (
        <div
            style={{
                background:
                    'linear-gradient(326.44deg, #FFFFFF 2.76%, rgba(230, 222, 245, 0.42) 25.38%, rgba(194, 173, 231, 0.28) 51.52%, rgba(240, 235, 249, 0.71) 72.95%, #FFFFFF 94%)',
                backgroundBlendMode: 'multiply',
            }}
        >
            <Layout environment="people" hasSearch hideSearchModal backgroundColor="bg-transparent">
                {/* NAV */}

                {/* BG GRADIENT */}

                <div className="max-w-7xl px-4 mx-auto">
                    {/* HERO */}
                    <div className="mx-auto text-center py-16 sm:max-w-6xl sm:py-24">
                        <h1 className="text-4xl text-gray-800 sm:text-8xl">
                            <div>
                                <Gradient theme="purple">
                                    Jobs at startups <br /> and scaleups
                                </Gradient>
                            </div>
                        </h1>
                        <p className="text-xl text-gray-800 mt-4 max-w-3xl mx-auto sm:text-2xl">
                            Where European talent matches with &#127475;&#127473; based companies. Search for jobs without the noise. No middlemen, no outdated
                            or duplicate jobs.
                        </p>
                    </div>

                    {/* SEARCH */}
                    <div className="max-w-4xl mx-auto">
                        <BasicSearchForm jobList={jobOptions} submitForm={(e) => navigateToSearch(e)} onChange={(value, type) => changeField(value, type)} />
                    </div>

                    {/* USP */}
                    <div className="max-w-5xl mx-auto py-16 text-center sm:py-24">
                        <h2 className="text-4xl text-gray-900 sm:text-5xl">
                            Why use <Gradient theme="purple">Otellu?</Gradient>
                        </h2>
                        <div className="flex flex-col gap-y-10 mt-12 sm:flex-row">
                            <h3 className="text-xl text-gray-800 sm:text-2xl">
                                <span className="text-brush-effect">50+ innovative</span> startups and scale ups
                            </h3>
                            <h3 className="text-xl text-gray-800 sm:text-2xl">
                                Only relevant jobs, powered by <span className="text-brush-effect">machine learning</span>
                            </h3>
                            <h3 className="text-xl text-gray-800 sm:text-2xl">
                                The most <span className="text-brush-effect">user friendly</span> platform you’ll find
                            </h3>
                        </div>
                    </div>

                    {/* Branches */}
                    <div className="max-w-5xl mx-auto py-16 sm:py-24">
                        <div className="flex flex-col items-center justify-center w-full mb-4">
                            <div className="inline-flex gap-x-2 items-center bg-purple-800 shadow-sm  rounded-full py-2 px-4 mb-2">
                                <p className="text-xl text-white font-bold">View our branches</p>
                                <ChevronDownIcon className="h-6 w-6 text-white animate-bounce" />
                            </div>
                            <p className="text-sm text-gray-500">Click on a panel to view jobs for that branch</p>
                        </div>

                        <div className="grid grid-cols-2 gap-8 sm:grid-cols-3">
                            <BranchCard
                                onClick={() => router.push('/s?branch=marketing')}
                                name="Marketing"
                                amount="48"
                                icon={<ChartPieIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=sales')}
                                name="Sales"
                                amount="42"
                                icon={<CalculatorIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=support')}
                                name="Support"
                                amount="32"
                                icon={<PhoneOutgoingIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=tech')}
                                name="Tech"
                                amount="44"
                                icon={<CodeIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=businessAndFinance')}
                                name="Business & Finance"
                                amount="47"
                                icon={<ChartBarIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=design')}
                                name="Design"
                                amount="20"
                                icon={<PencilAltIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=hr')}
                                name="HR"
                                amount="12"
                                icon={<UsersIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=accounting')}
                                name="Accounting"
                                amount="10"
                                icon={<CalculatorIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                            <BranchCard
                                onClick={() => router.push('/s?branch=legal')}
                                name="Legal"
                                amount="8"
                                icon={<AcademicCapIcon className="w-10 h-10 text-purple-800 group-hover:text-white" />}
                            />
                        </div>
                    </div>

                    {/* JOBS */}
                    {/* <div className="max-w-5xl mx-auto py-16 text-center sm:py-24">
                        <h2 className="text-4xl sm:text-5xl">
                            Check out these <Gradient theme="purple">brand new</Gradient> jobs
                        </h2>

                        <div className="grid grid-cols-1 gap-4 mt-12 sm:grid-cols-3">
                            {jobs.map((job, idx) => (
                                <JobCard
                                    index={idx}
                                    score={null}
                                    job={job._source}
                                    jobId={job._id}
                                    type="external"
                                    onSave={() => handleSaveJob(job._id)}
                                    media={{
                                        image: job._source.background_image,
                                        icon: job._source.icon,
                                    }}
                                    info={{
                                        title: job._source.title,
                                        bio: job._source.bio,
                                        companyName: job._source.company_name,
                                    }}
                                    specs={{
                                        branch: job._source.classifiedBranch,
                                        jobTitle: job._source.jobTitle,
                                        employmentType: job._source.employmentType,
                                        city: job._source.city,
                                        workLevel: job._source.workLevel[0],
                                    }}
                                />
                            ))}
                        </div>
                    </div> */}

                    {/* OUTRO SEARCH */}
                    <div className="max-w-5xl mx-auto text-center py-16 sm:py-24">
                        <h2 className="text-4xl sm:text-5xl">
                            Ready to find your new <Gradient theme="purple">future?</Gradient>
                        </h2>
                        <div className="mt-12">
                            <BasicSearchForm
                                jobList={jobOptions}
                                submitForm={(e) => navigateToSearch(e)}
                                onChange={(value, type) => changeField(value, type)}
                            />
                        </div>
                    </div>

                    {/* CREATE ACCOUNT CTA */}
                    <div className=" py-16 sm:py-24">
                        <div className="flex flex-col items-center bg-purple-800 rounded-2xl sm:flex-row">
                            <div className="px-8 py-8 flex-1 sm:py-0">
                                <h2 className="text-4xl text-white">Never miss an opportunity again</h2>
                                <p className="text-gray-100 mt-2">Creating an account only takes one minute. No spam, we promise.</p>
                                <div className="w-64 mt-4">
                                    <Button onClick={() => router.push('/signup')} colorScheme="whiteOutline" size="xl">
                                        Create an account
                                    </Button>
                                </div>
                            </div>
                            <img src="/assets/illustrations/isometric-icons.webp" />
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

// export async function getStaticProps() {
//     const res = await fetch(`${uo_url}/search/showcase?amount=3`);
//     const jobs_req = await res.json();
//     const jobs = jobs_req.external.brandNewJobs;
//     return {
//         props: {
//             jobs,
//         },
//         revalidate: 10, // In seconds
//     };
// }

export default Landing;
